// Styles
import "./Costs.scss";

// External Types
import { RecapPageComponentLine } from "@sky-uk/ita-api-quotations-sdk";

//Types
export type OneShotOrRecurringOrFinancial = "one-shot" | "recurring" | "financial";
export interface CostsProps {
	costs: RecapPageComponentLine;
	type: OneShotOrRecurringOrFinancial;
}
export const showProductFinalPriceComponent = (type: string, costLine: any) => {
	return showFinalPrice(
		type,
		"partialFinalPrice",
		"partialListPrice",
		"partialIsPrepaid",
		costLine,
		false
	);
};

export const showProductFinalPrice = (type: string, costLine: any) => {
	return showFinalPrice(type, "finalPrice", "listPrice", "isPrepaid", costLine, true);
};

const showFinalPrice = (
	type: string,
	finalPrice: string,
	listPrice: string,
	isPrepaid: string,
	costLine: any,
	totalPartial: boolean
) => {
	const isRecurring = type === "recurring";

	if (isRecurring && !!costLine?.[isPrepaid]) {
		return "PREPAGATO";
	}

	if (
		undefined !== costLine?.[finalPrice] &&
		totalPartial &&
		0 === costLine?.[finalPrice] &&
		0 === costLine?.[listPrice]
	) {
		return "INCLUSO";
	}

	return `${costLine?.[finalPrice]?.toFixed(2).replace(".", ",")}€`;
};

export const labelMapper: Record<string, string> = {
	"one-shot": "oneShot-title",
	financial: "financial-title"
};
export const priceLabelMapper: Record<string, string> = {
	"one-shot": "oneShot",
	financial: "financial"
};

// Element
export const Costs = (props: CostsProps) => {
	const { costs, type } = props;

	const titleClass = labelMapper[type] || "recurring-title";

	return (
		<>
			<div className="cost">
				<div className="cost__details title">
					<span className={`cost__label medium ${titleClass}`}>{costs?.title}</span>

					{costs?.partialListPriceLabel || costs?.partialValidityLabel ? (
						<div className={`cost__recurring ${titleClass}`}>
							{costs?.partialListPriceLabel && (
								<span className="medium bold">{costs.partialListPriceLabel}</span>
							)}
							{costs?.partialValidityLabel && (
								<span className="medium cost__validitylabel">
									{costs.partialValidityLabel}
								</span>
							)}
						</div>
					) : null}
				</div>
				<span className={`cost__price medium ${priceLabelMapper[type] || "recurring"}`}>
					{showProductFinalPriceComponent(type, costs)}{" "}
				</span>
			</div>

			{costs &&
				costs?.costLines &&
				costs?.costLines.map((cost: any, idx: number) => (
					<div className="cost" key={idx}>
						<div className="cost__details">
							<span className="cost__label">{cost?.productName}</span>

							{cost?.listPriceLabel || cost?.validityLabel ? (
								<div className="cost__recurring">
									{cost?.listPriceLabel && (
										<span className="medium bold">{cost.listPriceLabel}</span>
									)}
									{cost?.validityLabel && (
										<span className="medium cost__validitylabel">
											{cost.validityLabel}
										</span>
									)}
								</div>
							) : null}
						</div>

						{!!cost && typeof cost.finalPrice === "number" && (
							<span className="cost__price medium">
								{showProductFinalPrice(type, cost)}
							</span>
						)}
					</div>
				))}
		</>
	);
};

export default Costs;
