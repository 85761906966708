export const paymentType = {
	IBAN: {
		type: "Addebito su conto corrente",
		icon: "sepa"
	},
	CREDIT_CARD: {
		type: "Carta di credito",
		icon: "credit-card"
	},
	POSTAL_ORDER: {
		type: "Bollettino postale",
		icon: "postal"
	}
};

export const recurringType = {
	ONE_SHOT: "adesso",
	MONTHLY: "a partire dall'attivazione del servizio",
	YEARLY: "a partire dall'attivazione del servizio",
	BIMONTHLY: "a partire dall'attivazione del servizio"
};

export const frequencyType = {
	YEARLY: "annuale"
};
