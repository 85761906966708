// Style
import "./ReadyToSign.scss";

// Hooks & modules
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/store/hooks";

// Components
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";
import Button from "app/components/_shared/atoms/Button/Button";

// Redux
import { selectCurrentId, selectIsQuotationFreezed } from "app/store/recap/recapSlice";
import { setError } from "app/store/error/errorSlice";
import { setCurrentStep } from "app/store/metro/metroSlice";

// Utils
import { steps } from "utils/core/constants";
import { processDigitalData } from "utils/analytics";

// Types
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { useQuotationSignQuery } from "app/pages/recap/services/recap.service";
import { GetQuotationSignQuery } from "app/pages/recap/recap.types";
import { RecapPageSummarySection } from "@sky-uk/ita-api-quotations-sdk";
import {
	SignInData,
	selectSignatureConsents
} from "app/store/privacyConsents/privacyConsentsSlice";

export type ReadyToSignProps = {
	summary: RecapPageSummarySection;
	onlyStrictConsents: boolean;
	hasOffers: boolean;
	hasPrivacy: boolean;
};

let signatureTimer: TimeoutId;
const defaultModifierClassname = "isSign";
const doneModifierClassname = "green";

// Element
const ReadyToSign = ({ summary, onlyStrictConsents, hasOffers, hasPrivacy }: ReadyToSignProps) => {
	const {
		awaitingSignatureTitle,
		freezedTitle,
		offerSummaryText,
		generalConditionsSummaryText,
		privacySummaryText,
		contractSummarySummaryText,
		showSummaryTexts,
		freezedCtaDescription,
		awaitingSignatureCtaDescription,
		enabledCta,
		disabledCta
	} = summary;
	const customerConsents = useAppSelector<SignInData>(selectSignatureConsents);
	const isFreezed = useAppSelector<boolean>(selectIsQuotationFreezed);
	const [btnModifier, setBtnModifier] = useState<string>(defaultModifierClassname);
	const [signed, setSigned] = useState<boolean>(false);
	const quotationId =
		useAppSelector(selectCurrentId) ||
		/* istanbul ignore next: this is a fallback always true */ "";
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const bearer =
		sessionStorage.getItem("slt") ||
		/* istanbul ignore next: this is a fallback always true */ "";
	const pathName = window.location.pathname;
	const enabledButtonLabel = signed ? enabledCta?.ctaClickedLabel : enabledCta?.ctaLabel;
	const buttonLabel = !isFreezed ? enabledButtonLabel : disabledCta?.ctaLabel;
	const sectionTitle = isFreezed ? freezedTitle! : awaitingSignatureTitle!;

	const requestData = !!Object.keys(customerConsents).length ? { customerConsents } : {};

	const body = JSON.stringify({
		quotationId,
		bearer,
		request: requestData
	});

	const skip = !(signed && quotationId);
	const { data: dataSign, error: errorSign }: GetQuotationSignQuery =
		useQuotationSignQuery<GetQuotationSignQuery>(body, { skip });

	useEffect(() => {
		/* istanbul ignore else */
		if (errorSign) {
			dispatch(setError(errorSign));
			dispatch(setCurrentStep(steps[0]));
			navigate("/error", { state: { currentUrl: window.location.href } });
		}
		/* istanbul ignore else */
		if (dataSign) {
			dispatch(setCurrentStep(steps[0]));
			setTimeout(
				() => navigate("/done", { state: { currentUrl: window.location.href } }),
				2000
			);
		}
	}, [dataSign, errorSign, navigate, dispatch]);

	const onSignatureComplete = () => {
		/* istanbul ignore else */
		if (!signed) {
			setSigned(true);
			setBtnModifier(doneModifierClassname);
		}
	};
	const onSignatureStart = () => {
		/* istanbul ignore else */
		if (!signed) {
			processDigitalData({
				label: `digital signature – ${sectionTitle.toLowerCase()} – ${buttonLabel?.toLowerCase()}`,
				destinationURL: window.location.href,
				cleanUrl: window.location.origin + pathName
			});
			setBtnModifier("isSign signing");
			signatureTimer = setTimeout(onSignatureComplete, 2000);
		}
	};
	const onSignatureLeave = () => {
		/* istanbul ignore else */
		if (!signed && signatureTimer) {
			setBtnModifier(defaultModifierClassname);
			clearTimeout(signatureTimer);
		}
	};

	const recapPoint = (consentText: string) => {
		return (
			<div className="RecapPoint">
				<div className="icon">
					<IconSwitcher icon="Tick" />
				</div>
				<p key="text" className="ready-to-sign__accepted-consents">
					{consentText}
				</p>
			</div>
		);
	};

	return (
		<div className="ready-to-sign">
			<h2
				className="ready-to-sign__title"
				dangerouslySetInnerHTML={{ __html: sectionTitle }}
			></h2>

			{isFreezed ? (
				<p className="ready-to-sign__accepted-consents">{freezedCtaDescription}</p>
			) : (
				<>
					{hasOffers &&
						offerSummaryText &&
						showSummaryTexts &&
						recapPoint(offerSummaryText)}
					{!onlyStrictConsents &&
						generalConditionsSummaryText &&
						showSummaryTexts &&
						recapPoint(generalConditionsSummaryText)}
					{hasPrivacy &&
						privacySummaryText &&
						showSummaryTexts &&
						recapPoint(privacySummaryText)}
					{!onlyStrictConsents &&
						contractSummarySummaryText &&
						showSummaryTexts &&
						recapPoint(contractSummarySummaryText)}
					{showSummaryTexts && (
						<span className="ready-to-sign__disclaimer medium">
							{awaitingSignatureCtaDescription}
						</span>
					)}
				</>
			)}

			<Button
				onMouseDown={onSignatureStart}
				onMouseUp={onSignatureLeave}
				onMouseOut={onSignatureLeave}
				onTouchStart={onSignatureStart}
				onTouchEnd={onSignatureLeave}
				disabled={isFreezed}
				additionalClassName={btnModifier}
			>
				{buttonLabel}
			</Button>
		</div>
	);
};

export default ReadyToSign;
