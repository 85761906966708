import { Step } from "app/components/metro/Metro";

// ALL STEPS
export const steps: Step[] = [
	{ index: 1, pageName: "/sign" },
	{ index: 2, pageName: "/sign-2" },
	{ index: 3, pageName: "/sign-3" }
];

export const stepLabels: string[] = ["Primo Step", "Secondo Step", "Terzo Step"];
