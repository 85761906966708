import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ErrorOtp, GetQuotationInfoQueryError } from "app/pages/recap/recap.types";
import { formatError } from "utils";

export interface ErrorState {
	data: {
		title: string;
		text: string;
		pageCorporateConfiguration?: {
			pageCorporateLabel: string;
		};
	};
}

const initialState: ErrorState = {
	data: {
		title: "",
		text: ""
	}
};

export const errorSlice = createSlice({
	name: "error",
	initialState,
	reducers: {
		setError: (
			error: ErrorState,
			action: PayloadAction<
				FetchBaseQueryError | GetQuotationInfoQueryError | ErrorOtp | SerializedError
			>
		) => {
			error.data = formatError(action.payload);
		}
	}
});

export const { setError } = errorSlice.actions;
export const selectError = (state: RootState) => state.error;

export default errorSlice.reducer;
