// Style
import "./Typ.scss";

// Hooks & modules
import { useEffect } from "react";

// Components
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";

// Utils
import { processDigitalData } from "utils/analytics";

// Element
const ThankYouPage = () => {
	const pathName = window.location.pathname;

	useEffect(() => {
		processDigitalData({
			pageName: "sky:digital signature:typ",
			pageType: "page",
			destinationURL: window.location.href,
			cleanUrl: window.location.origin + pathName
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="typ">
			<IconSwitcher icon="TickCircle" className="typ_icon" />
			<h2 data-testid="typ_title" className="typ_title">
				Grazie!
			</h2>
			<p data-testid="typ_description" className="typ_description">
				Controlla la tua casella di posta. Riceverai una mail di conferma con il riepilogo
				dell'offerta e i prossimi passi.
			</p>
		</div>
	);
};

export default ThankYouPage;
