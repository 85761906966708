// Styles
import "./Loader.scss";

// Element
const Loader = () => {
	return (
		<div className="brand-loader">
			<div className="brand-loader__container">
				<svg className="brand-loader__container__spinner" viewBox="25 25 50 50">
					<defs>
						<linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" stopColor="#ff9e00" />
							<stop offset="25%" stopColor="#ff0000" />
							<stop offset="50%" stopColor="#b5007d" />
							<stop offset="75%" stopColor="#21429c" />
							<stop offset="100%" stopColor="#0071ff" />
						</linearGradient>
					</defs>
					<circle
						className="brand-loader__container__spinner__path"
						cx="50"
						cy="50"
						r="20"
						stroke="url(#gradient)"
						fill="none"
						strokeWidth="0.7"
						strokeMiterlimit="10"
					/>
				</svg>
			</div>
			<div className="brand-loader__logo"></div>
		</div>
	);
};

export default Loader;
