// Styles
import "./Privacy.scss";

// Hooks & modules
import { useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/store/hooks";

// Components
import Panel from "app/components/panel/Panel";
import Button from "app/components/_shared/atoms/Button/Button";
import Checkbox from "app/components/_shared/atoms/Checkbox/Checkbox";

// Redux
import {
	setMandatoryConsentsState,
	setOptionalConsentsState,
	selectOptionalConsents,
	selectMandatoryConsents
} from "app/store/privacyConsents/privacyConsentsSlice";

// Utils
import { scrollToEl } from "utils";

// Types
import {
	RecapPageMandatoryConsentItem,
	RecapPageOptionalConsentItem,
	RecapPageCta
} from "@sky-uk/ita-api-quotations-sdk";

export interface PrivacyProps {
	currentIndex: number;
	isFreezed: boolean;
	acceptances: HTMLCollectionOf<Element>;
	title?: string;
	cta?: RecapPageCta;
}

export interface RecapPageMandatoryConsentItemExtended extends RecapPageMandatoryConsentItem {
	given: boolean;
}

export type ConsentTypes = RecapPageMandatoryConsentItemExtended[] | RecapPageOptionalConsentItem[];

export type ConsentObjectTypes = "mandatoryConsents" | "optionalConsents";

const checkboxStatus: Record<number, boolean> = {
	0: true,
	1: true,
	2: false
};

const privacyOptionalDescription =
	"Dopo aver letto l'informativa, puoi scegliere se autorizzare Sky al trattamento dei tuoi dati per tutte, alcune o nessuna delle finalità seguenti (seleziona solo i campi che ti interessano, o nessuno se non ci vuoi autorizzare):";

// Element
const Privacy = ({ title, cta, currentIndex, acceptances, isFreezed }: PrivacyProps) => {
	const [error, setError] = useState(false);
	const allCheckedRef = useRef<number>(0);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const optionalConsents = useAppSelector<RecapPageOptionalConsentItem[]>(selectOptionalConsents);
	const mandatoryConsents =
		useAppSelector<RecapPageMandatoryConsentItemExtended[]>(selectMandatoryConsents);

	const checkboxHandler = useCallback(
		(type: ConsentObjectTypes, consentId: string | undefined) => {
			const options = type === "mandatoryConsents" ? mandatoryConsents : optionalConsents;

			const cloneConsents = options.map((item) =>
				item.consentId === consentId ? { ...item, given: !item.given } : item
			);

			/* istanbul ignore else */
			if (type === "mandatoryConsents") {
				dispatch(
					setMandatoryConsentsState(
						cloneConsents as RecapPageMandatoryConsentItemExtended[]
					)
				);
			}
			/* istanbul ignore else */
			if (type === "optionalConsents") {
				dispatch(setOptionalConsentsState(cloneConsents));
			}
		},
		[dispatch, mandatoryConsents, optionalConsents]
	);

	const handleAllCheckbox = useCallback(() => {
		const cloneConsents = optionalConsents?.map((consent) => ({
			...consent,
			given: consent.visible ? checkboxStatus[allCheckedRef.current] : consent.given
		}));
		dispatch(setOptionalConsentsState(cloneConsents));
	}, [dispatch, optionalConsents]);

	const handleButtonClick = () => {
		if (mandatoryConsents?.every((el) => el.given)) {
			setError(false);
			navigate(`/sign-3${search}`);
		} else {
			scrollToEl(".privacy__mandatory", "cls");
			setError(true);
		}
	};

	const currentOptionalConsents = optionalConsents?.filter(
		(optionalItem) => optionalItem?.visible
	);

	const allTrue = optionalConsents.filter((el) => el.visible).every((consent) => consent.given);
	const allFalse = optionalConsents.filter((el) => el.visible).every((consent) => !consent.given);
	const allChecked = allTrue ? 2 : allFalse ? 0 : 1;
	allCheckedRef.current = allChecked;

	return (
		<div
			className={`privacy ${
				currentIndex > acceptances.length || isFreezed ? "unlocked" : ""
			}`}
		>
			<p className="privacy__title medium">{title}</p>
			<Panel __className="privacy__panel">
				<div className="privacy__mandatory">
					{mandatoryConsents?.map((mandatoryItem, i) => (
						<div
							className="privacy__item"
							key={`${mandatoryItem.consentId}-mandatory-${i}`}
						>
							<Checkbox
								onChange={() =>
									checkboxHandler("mandatoryConsents", mandatoryItem?.consentId)
								}
								checked={mandatoryItem.given}
								error={error}
								disabled={isFreezed}
								additionalClassName="privacy__item"
							>
								<p className="privacy__item--title">{mandatoryItem.title}</p>
							</Checkbox>
							{mandatoryItem.links?.map((link, idx) => (
								<a
									key={idx}
									href={link.url}
									target={link.blank ? "_blank" : "_self"}
									rel="noreferrer"
									className={`privacy__link medium ${error ? "error" : ""}`}
								>
									{link.label}
								</a>
							))}
							{mandatoryItem?.description && (
								<p
									className="privacy__description"
									dangerouslySetInnerHTML={{
										__html: mandatoryItem.description
									}}
								/>
							)}
						</div>
					))}
				</div>
				<span className="privacy__divider" />
				{!!currentOptionalConsents?.length && (
					<div className="privacy__optional">
						<p className="privacy__optional--description">
							{privacyOptionalDescription}
						</p>
						<Checkbox
							middleStatus={allChecked === 1}
							onChange={handleAllCheckbox}
							checked={[1, 2].includes(allChecked)}
							disabled={isFreezed}
							additionalClassName="privacy__item"
						>
							<p className="privacy__item--title">Seleziona tutti</p>
						</Checkbox>
						<span className="privacy__divider inside" />
						{currentOptionalConsents?.map((optionalItem) => (
							<Checkbox
								key={`${optionalItem.consentId}-optional`}
								onChange={() =>
									checkboxHandler("optionalConsents", optionalItem?.consentId)
								}
								checked={Boolean(optionalItem.given)}
								disabled={isFreezed}
								additionalClassName="privacy__item"
							>
								<p className="privacy__item--title">{optionalItem.title}</p>
								<p className="privacy__item--description">
									{optionalItem.description}
								</p>
							</Checkbox>
						))}
					</div>
				)}
			</Panel>
			{!isFreezed && (
				<Button additionalClassName="privacy__button" onClick={handleButtonClick}>
					{cta?.ctaLabel}
				</Button>
			)}
		</div>
	);
};

export default Privacy;
