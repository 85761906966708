import { useEffect } from "react";

const useDisableScroll = (stateToggled: boolean) => {
	useEffect(() => {
		if (stateToggled) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	}, [stateToggled]);
};

export default useDisableScroll;
