import { useEffect } from "react";

const useOutsideClick = (ref: any, callback: () => void) => {
	const handleClick = (ev: MouseEvent) => {
		/* istanbul ignore else */
		if (ref.current && !ref.current.contains(ev.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => document.removeEventListener("mousedown", handleClick);
	});
};

export default useOutsideClick;
