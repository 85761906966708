// Style
import "./OffersList.scss";

// Components
import Accordion from "../accordion/Accordion";
import Offer from "../offer/Offer";

// External Type
import {
	RecapPageOfferAdditionalDetail,
	RecapPageOfferSection
} from "@sky-uk/ita-api-quotations-sdk";
import { RecapPageOfferCostsSection } from "@sky-uk/ita-api-quotations-sdk/model/recapPageOfferCostsSection";

// Element
export const OffersList = (offers: RecapPageOfferSection) => {
	const { name: packageName } = offers;

	return (
		<section className="offers-list__wrp">
			<p className="medium sky-body offers-list__title">{packageName}</p>

			{offers.costs?.map((costs: RecapPageOfferCostsSection, idx: number) => (
				<Offer key={idx} {...costs} />
			))}

			{offers?.additionalDetails?.map(
				(row: RecapPageOfferAdditionalDetail, index: number) => {
					return (
						<Accordion
							isOffersAccordion={true}
							key={index + 10}
							index={index + 10}
							title={row.additionalDetailsHeading}
							contentDetailOffer={
								row.additionalDetailsItems
									? row.additionalDetailsItems
									: /* istanbul ignore next: this is a fallback always true */ []
							}
						/>
					);
				}
			)}
		</section>
	);
};

export default OffersList;
