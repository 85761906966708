import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface RecapState {
	currentId: string | null;
	isQuotationFreezed: boolean;
}

const initialState: RecapState = {
	currentId: "",
	isQuotationFreezed: false
};

export const recapSlice = createSlice({
	name: "recap",
	initialState,
	reducers: {
		setCurrentId: (state, action: PayloadAction<string>) => {
			state.currentId = action.payload;
		},
		setQuotationFreezeStatus: (state, action: PayloadAction<boolean>) => {
			state.isQuotationFreezed = action.payload;
		}
	}
});

export const { setCurrentId, setQuotationFreezeStatus } = recapSlice.actions;

export const selectCurrentId = (state: RootState) => state.recap.currentId;
export const selectIsQuotationFreezed = (state: RootState) => state.recap.isQuotationFreezed;

export default recapSlice.reducer;
