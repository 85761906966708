import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecapPageMandatoryConsentItemExtended } from "app/components/privacy/Privacy";
import { RootState } from "../store";

import { RecapPageOptionalConsentItem } from "@sky-uk/ita-api-quotations-sdk";

export interface PrivacyConsentState {
	currentIndex: number;
	mandatoryConsents: RecapPageMandatoryConsentItemExtended[];
	optionalConsents: RecapPageOptionalConsentItem[];
}

export type SignInData = Record<string, Record<string, boolean | undefined>>;

const initialState: PrivacyConsentState = {
	currentIndex: 1,
	mandatoryConsents: [],
	optionalConsents: []
};

export const privacyConsentsSlice = createSlice({
	name: "privacyConsents",
	initialState,
	reducers: {
		setCurrentIndex: (state, action: PayloadAction<number>) => {
			state.currentIndex = action.payload;
		},
		setMandatoryConsentsState: (
			state,
			action: PayloadAction<RecapPageMandatoryConsentItemExtended[]>
		) => {
			state.mandatoryConsents = action.payload;
		},
		setOptionalConsentsState: (
			state,
			action: PayloadAction<RecapPageOptionalConsentItem[]>
		) => {
			state.optionalConsents = action.payload;
		}
	}
});

export const { setCurrentIndex, setMandatoryConsentsState, setOptionalConsentsState } =
	privacyConsentsSlice.actions;

export const selectCurrentIndex = (state: RootState) => state.privacyConsents.currentIndex;
export const selectMandatoryConsents = (state: RootState) =>
	state.privacyConsents.mandatoryConsents;
export const selectOptionalConsents = (state: RootState) => state.privacyConsents.optionalConsents;
export const selectSignatureConsents = createSelector(
	selectOptionalConsents,
	(state): SignInData => {
		const consents: SignInData = {};
		state?.forEach(({ consentId, given, visible }) => {
			/* istanbul ignore else */
			if (consentId) {
				consents[consentId] = { given, visible };
			}
		});
		return consents;
	}
);

export default privacyConsentsSlice.reducer;
