// Styles
import "./Unauthorized.scss";

// Hooks & modules
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { useLocation, useNavigate } from "react-router-dom";

// Redux
import { selectCurrentId } from "app/store/recap/recapSlice";
import { setError } from "app/store/error/errorSlice";

// Components
import Loader from "app/components/loader/Loader";

// Types
import {
	AuthorizeOTP,
	useGetAuthorizeOTPQuery
} from "app/pages/recap/services/token-manager-service.service";
import recapApi from "app/pages/recap/services/recap.service";

// Element
const UnauthorizedPage = () => {
	const id =
		useAppSelector(selectCurrentId) ||
		/* istanbul ignore next: this is a fallback always true */ "";
	const { state } = useLocation() as { state: { currentUrl: string } };
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { currentData: dataInfo, error: errorInfo } =
		recapApi.endpoints.getQuotationInfo.useQueryState(id);

	const authorizeOTPBody: AuthorizeOTP = {
		id: id,
		destUrl: encodeURIComponent(state?.currentUrl),
		epn: encodeURIComponent(dataInfo?.epn as string),
		sourceId: dataInfo?.sourceSystem
	};

	const { data, error: errorOtp } = useGetAuthorizeOTPQuery(authorizeOTPBody);

	useEffect(() => {
		/* istanbul ignore else */
		if (data?.url && dataInfo && id) {
			window.location.href = data.url;
		}
		const errorData = errorInfo || errorOtp;
		/* istanbul ignore else */
		if (errorData) {
			dispatch(setError(errorData));
			navigate("/error", { state: { currentUrl: window.location.href } });
		}
	}, [data, dataInfo, id, errorInfo, errorOtp, dispatch, navigate]);

	useEffect(() => {
		/* istanbul ignore else */
		if (!state) {
			navigate("/");
		}
	}, [navigate, state]);

	return (
		<div className="unauthorized">
			<Loader />
		</div>
	);
};

export default UnauthorizedPage;
