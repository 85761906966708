/* istanbul ignore file */
// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthsOTP, AuthsOTPBody } from "app/pages/recap/recap.types";

export interface AuthorizeOTP {
	id: string;
	destUrl: string;
	epn?: string;
	sourceId?: string;
}

// Define a service using a base URL and expected endpoints
export const tokenManagerApi = createApi({
	reducerPath: "tokenManagerApi",
	baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_TM_API_URL }),
	endpoints: (builder) => ({
		getAuthorizeOTP: builder.query<{ url: string }, AuthorizeOTP>({
			query: ({ id, destUrl, epn, sourceId }: AuthorizeOTP) => {
				if (!id || !destUrl || !epn || !sourceId) {
					throw new Error(
						`Quotation one of those params are undefined: ${id}, ${destUrl}, ${epn}, ${sourceId}`
					);
				}
				return {
					url: `${process.env.REACT_APP_TM_API_URL}/authorizeOTP?id=${id}&destUrl=${destUrl}&epn=${epn}&sourceId=${sourceId}`,
					method: "GET",
					headers: { "Content-Type": "application/json" }
				};
			}
		}),
		authsOtp: builder.query<AuthsOTP, AuthsOTPBody>({
			query: (body: AuthsOTPBody) => ({
				url: "authsOTP",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body
			})
		})
	})
});

export const { useGetAuthorizeOTPQuery, useAuthsOtpQuery } = tokenManagerApi;
export default tokenManagerApi;
