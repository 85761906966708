// Styles
import "./App.scss";

// Hooks & modules
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
	useGetQuotationInfoQuery,
	useGetQuotationViewQuery
} from "./pages/recap/services/recap.service";
import { useAuthsOtpQuery } from "./pages/recap/services/token-manager-service.service";
import {
	selectIsQuotationFreezed,
	setCurrentId,
	setQuotationFreezeStatus
} from "./store/recap/recapSlice";
import { selectCurrentStep, setSteps } from "./store/metro/metroSlice";
import { setError } from "./store/error/errorSlice";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

// Components
import Footer from "./components/footer/Footer";
import ThankYouPage from "./pages/typ/Typ";
import RecapPage from "./pages/recap/Recap";
import CGAPage from "./pages/CGA/CGA";
import DonePage from "./pages/done/done";
import UnauthorizedPage from "./pages/unauthorized/Unauthorized";
import ErrorPage from "./pages/error/Error";
import Drawer from "./components/drawer/Drawer";
import Header from "./components/header/Header";
import Loader from "./components/loader/Loader";

// Utils
import ScrollToTop from "app/components/_shared/atoms/ScrollToTop/ScrollToTop";
import { DSInterceptor } from "utils/interceptor";
import Metro, { Step } from "./components/metro/Metro";
import { steps } from "../utils/core/constants";
import { metroVariants } from "../utils/core/motionConfig";

// Store
import { setDrawerContent } from "./store/drawer/drawer";
import {
	setMandatoryConsentsState,
	setOptionalConsentsState
} from "./store/privacyConsents/privacyConsentsSlice";

// Types
import {
	GetQuotationInfoQuery,
	GetQuotationViewQuery,
	PostAuthsOtpQuery,
	AuthsOTPBody
} from "app/pages/recap/recap.types";
interface SignatureStatusInterface {
	AWAITING: string;
	FREEZED: string;
}

// Enum
enum SignatureStatus {
	AWAITING = "AWAITING_SIGNATURE",
	FREEZED = "FREEZED"
}

const App = () => {
	DSInterceptor();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const id =
		searchParams.get("qid") || /* istanbul ignore next: this is a fallback always true */ "";
	const authCode =
		searchParams.get("authCode") ||
		/* istanbul ignore next: this is a fallback always true */ "";
	const currentStep = useAppSelector<Step>(selectCurrentStep);
	const isFreezed = useAppSelector<boolean>(selectIsQuotationFreezed);

	useEffect(() => {
		/* istanbul ignore else */
		if (window.location.pathname !== steps[0].pageName) {
			navigate(`${steps[0].pageName}?${searchParams}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		data: dataInfo,
		error: errorInfo,
		isLoading: isLoadingInfo,
		isFetching: isFetchingInfo
	}: GetQuotationInfoQuery = useGetQuotationInfoQuery<GetQuotationInfoQuery>(id, { skip: !id });

	const { AWAITING, FREEZED }: SignatureStatusInterface = SignatureStatus;
	const slt =
		sessionStorage.getItem("slt") ||
		/* istanbul ignore next: this is a fallback always true */ "";
	const rtk = sessionStorage.getItem("rtk");
	const status = dataInfo?.status;
	const sourceId = dataInfo?.sourceSystem;
	const skip = !status || !authCode || ![AWAITING, FREEZED].includes(status) || !!(slt && rtk);
	const body = { authCode, id, sourceId } as AuthsOTPBody;
	const { data: dataAuths, error: errorAuths }: PostAuthsOtpQuery =
		useAuthsOtpQuery<PostAuthsOtpQuery>(body, { skip });

	const [skipView, setSkipView] = useState(!!authCode && !slt);

	const {
		data: dataView,
		isLoading: isLoadingView,
		error: errorView,
		isFetching: isFetchingView,
		refetch: refetchView
	}: GetQuotationViewQuery = useGetQuotationViewQuery<GetQuotationViewQuery>(
		{ quotationId: id, bearer: slt },
		{ skip: skipView || !id || !dataInfo || !!errorInfo }
	);

	useEffect(() => {
		/* istanbul ignore else */
		if (dataView?.isQuotationFreezed !== undefined) {
			dispatch(setQuotationFreezeStatus(dataView.isQuotationFreezed));
		}

		/* istanbul ignore else */
		if (dataView?.offerSection?.drawer) {
			dispatch(setDrawerContent(dataView.offerSection?.drawer));
		}

		/* istanbul ignore else */
		if (dataView?.privacySection?.mandatoryConsentItems) {
			const formattedMandatoryConsents = [
				...dataView?.privacySection?.mandatoryConsentItems
			].map((item) => ({ ...item, given: false }));
			dispatch(setMandatoryConsentsState(formattedMandatoryConsents));
		}
		/* istanbul ignore else */
		if (dataView?.privacySection?.optionalConsentItems) {
			dispatch(setOptionalConsentsState(dataView?.privacySection?.optionalConsentItems));
		}

		/* istanbul ignore else */
		if (dataView) {
			const { privacySection, generalConsentsSections } = dataView;
			const availableSteps = [...steps];
			/* istanbul ignore else */
			if (
				(!privacySection || Object.keys(privacySection).length === 0) &&
				!generalConsentsSections?.length
			) {
				availableSteps.splice(1, 1);
			}
			const signatureSteps = availableSteps.map(({ pageName }, i) => ({
				pageName,
				index: i + 1
			}));
			dispatch(setSteps(signatureSteps));
		}
	}, [dataView, dispatch]);

	useEffect(() => {
		/* istanbul ignore else */
		if (dataAuths?.slt && dataAuths?.rtk) {
			sessionStorage.setItem("slt", dataAuths.slt);
			sessionStorage.setItem("rtk", dataAuths.rtk);
			setSkipView(false);
			/* istanbul ignore else */
			if (dataView) {
				refetchView();
			}
		}
	}, [dataAuths, refetchView, dataView]);

	useEffect(() => {
		/* istanbul ignore else */
		if (id) {
			dispatch(setCurrentId(id));
		}
		/* istanbul ignore else */
		if ((errorView && errorView.status === 401 && dataInfo && id) || errorAuths) {
			navigate("/unauthorized", { state: { currentUrl: window.location.href } });
		}
		/* istanbul ignore else */
		if (
			((errorView && errorView.status !== 401) || errorInfo || errorAuths) &&
			(!isLoadingInfo || !isLoadingView)
		) {
			dispatch(setError(errorView || errorInfo || errorAuths));
			navigate("/error", { state: { currentUrl: window.location.href } });
		}
	}, [
		errorView,
		errorInfo,
		errorAuths,
		isLoadingInfo,
		isLoadingView,
		navigate,
		dataInfo,
		dispatch,
		id
	]);

	return (
		<div className={`App ${isFreezed ? "freezed" : ""}`}>
			<ScrollToTop />
			<Header
				expiryWarning={
					window.location.pathname !== "/done" && dataView?.heading?.expiryWarning
				}
			/>
			<Drawer />
			{isLoadingInfo || isLoadingView || isFetchingInfo || isFetchingView ? (
				<div className="loader-container">
					<Loader />
				</div>
			) : (
				<>
					<motion.div
						className="metro-motion"
						animate="visible"
						variants={metroVariants}
						custom={{ currentStep, steps }}
					>
						<Metro currentStep={currentStep} />
					</motion.div>
					<Routes>
						<Route path="/sign" element={<RecapPage dataView={dataView} />} />
						<Route path="/sign-2" element={<CGAPage dataView={dataView} />} />
						<Route path="/sign-3" element={<DonePage dataView={dataView} />} />
						<Route path="/unauthorized" element={<UnauthorizedPage />} />
						<Route path="/error" element={<ErrorPage />} />
						<Route path="/done" element={<ThankYouPage />} />
					</Routes>
				</>
			)}
			{isFreezed && <Footer text={dataView?.footer?.text} link={dataView?.footer?.link} />}
		</div>
	);
};

export default App;
