/* istanbul ignore file */
// the interceptor configuration is coming from a third-party package and it is already been tested internally.
import fetchIntercept from "fetch-intercept";

type HeaderDataType = {
	key?: string;
	value?: string;
};

const x_sky_client_header: HeaderDataType = {
	key: process.env.REACT_APP_HEADERS_X_SKY_CLIENT_NAME,
	value: process.env.REACT_APP_HEADERS_X_SKY_CLIENT_VALUE
};

const injectRequestHeaders = (url: any, new_headers: any) => {
	new_headers.forEach((new_header: HeaderDataType) => {
		/* istanbul ignore else */
		if (!url.headers.get(new_header.key)) {
			url.headers.set(new_header.key, new_header.value);
		}
	});
	return url;
};

const new_headers = [x_sky_client_header];

export const DSInterceptor = () => {
	fetchIntercept.register({
		request: function (url: any, config: any) {
			url = injectRequestHeaders(url, new_headers);
			return [url, config];
		},
		requestError: function (error: any) {
			// Called when an error occured during another 'request' interceptor call
			return Promise.reject(error);
		},

		response: function (response: any) {
			// Modify the reponse object
			return response;
		},

		responseError: function (error: any) {
			// Handle an fetch error
			return Promise.reject(error);
		}
	});
};
