// Styles
import "./Footer.scss";

// Modules & hooks
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "app/store/hooks";

// Redux
import { selectCurrentStep, selectSteps } from "app/store/metro/metroSlice";

// Components

// External Types
import { RecapPageFooterSection } from "@sky-uk/ita-api-quotations-sdk";
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";

// Element
const Footer = ({ text, link }: RecapPageFooterSection) => {
	const index = useAppSelector(selectCurrentStep)?.index;
	const currentStep = index - 1;
	const steps = useAppSelector(selectSteps);
	const navigate = useNavigate();
	const { search } = useLocation();

	const stepHandler = (newIdx: number) => {
		navigate(`${steps[newIdx].pageName}${search}`);
	};

	return (
		<footer className="footer">
			<div className="footer__text">
				{link && [
					<IconSwitcher key="footer-icon" icon="Phone" className="footer__icon" />,
					<div className="footer__text-wrp" key="footer-link">
						{text && <p className="footer__text--title">{text}</p>}
						<a
							href={link.url}
							target={link.blank ? "_blank" : "_self"}
							rel="noreferrer"
							className="footer__text--link medium blue"
						>
							{link.label}
						</a>
					</div>
				]}
			</div>
			<div className="footer__navigation">
				<div
					className={`footer__navigation-control previous medium blue ${
						currentStep === 0 ? "disabled" : ""
					}`}
					onClick={() => stepHandler(currentStep - 1)}
				>
					<IconSwitcher
						icon="ChevronDown"
						className="chevron icon-previous"
						fill="#000FF5"
					/>
					<span>Indietro</span>
				</div>
				<div
					className={`footer__navigation-control next medium blue ${
						currentStep === steps.length - 1 ? "disabled" : ""
					}`}
					onClick={() => stepHandler(currentStep + 1)}
				>
					<span>Avanti</span>
					<IconSwitcher icon="ChevronDown" className="chevron icon-next" fill="#000FF5" />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
