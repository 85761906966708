/* istanbul ignore file */
// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { QuotationInfo, QuotationView, SignQuotationRequest } from "@sky-uk/ita-api-quotations-sdk";

// Define a service using a base URL and expected endpoints
export const recapApi = createApi({
	reducerPath: "recapApi",
	baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_CNT_API_URL }),
	endpoints: (builder) => ({
		getQuotationView: builder.query<QuotationView, { quotationId: string; bearer: string }>({
			query: ({ quotationId, bearer }: { quotationId: string; bearer: string }) => ({
				url: `quotations/${quotationId}/view`,
				headers: { Authorization: `Bearer ${bearer}` }
			})
		}),
		getQuotationInfo: builder.query<QuotationInfo, string>({
			async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
				const result = await fetchWithBQ(`quotations/${_arg}/info`);
				return result.data
					? { data: result.data as QuotationInfo }
					: { error: result.error as FetchBaseQueryError };
			}
		}),
		quotationSign: builder.query<SignQuotationRequest, string>({
			query: (bodyStr: string) => {
				const body = JSON.parse(bodyStr);
				const quotationId = body?.quotationId;
				const bearer = body?.bearer;
				const request = body?.request as SignQuotationRequest;
				return {
					url: `quotations/${quotationId}/sign`,
					method: "POST",
					headers: {
						Authorization: `Bearer ${bearer}`,
						"Content-Type": "application/json"
					},
					body: request
				};
			}
		})
	})
});

export const { useGetQuotationViewQuery, useGetQuotationInfoQuery, useQuotationSignQuery } =
	recapApi;
export default recapApi;
